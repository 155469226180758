<template>
  <div class="stockDetail">
    <h1>融资详情</h1>
    <!--<Icon type="ios-redo"
          class="goback"
          @click="back" />-->
    <div style="border-top: 1px solid rgba(214, 215, 217, 1)">
      <div class="table-title">{{ showData.ggbt }}</div>
      <cTableItem
        :titleArr="['股票代码', '企业全称']"
        :contentArr="[showData.gpdm, showData.qyqc]"
      ></cTableItem>
      <cTableItem
        :titleArr="['企业简称']"
        :contentArr="[showData.qyjc]"
      ></cTableItem>
      <cTableItem
        :titleArr="['省份', '城市']"
        :contentArr="[showData.sf, showData.cs]"
      ></cTableItem>
      <cTableItem
        :titleArr="['公告日期', '实际融资日期']"
        :contentArr="[showData.ggrq, showData.sjrzrq]"
      ></cTableItem>
      <cTableItem
        :titleArr="['是否担保', '融资状态']"
        :contentArr="[showData.sfdb, showData.rzzt]"
      ></cTableItem>
      <cTableItem
        :titleArr="['融资方式']"
        :contentArr="[showData.rzfs]"
      ></cTableItem>
      <cTableItem
        :titleArr="['币种', '融资规模（亿元）']"
        :contentArr="[showData.bz, showData.rzgmyy]"
      ></cTableItem>
      <cTableItem
        :titleArr="['融资利率', '期限']"
        :contentArr="[showData.rzll, showData.qx]"
      ></cTableItem>
      <cTableItem
        :titleArr="['到期日期', '用途']"
        :contentArr="[showData.dqrq, showData.yt]"
      ></cTableItem>
      <cTableItem
        :titleArr="['实际融资人简称', '实际融资人']"
        :contentArr="[showData.sjrzrjc, showData.sjrzr]"
      ></cTableItem>
      <cTableItem
        :titleArr="['融资前股权比例', '股权比例']"
        :contentArr="[showData.rzqgqbl, showData.gqbl]"
      ></cTableItem>

      <cTableItem
        :titleArr="['贷款方/资金提供方', '资金来源管道']"
        :contentArr="[showData.dkfzjtgf, showData.zjlygd]"
      ></cTableItem>

      <cTableItem
        :titleArr="['是否为内部融资', '抵押物']"
        :contentArr="[showData.sfwnbrz, showData.dyw]"
      ></cTableItem>

      <cTableItem
        :titleArr="['担保方式', '质押物']"
        :contentArr="[showData.dbfs, showData.zyw]"
      ></cTableItem>
      <cTableItem
        :titleArr="['担保人', '担保金额（亿元）']"
        :contentArr="[showData.dbr, showData.dbjeyy]"
      ></cTableItem>
      <cTableItem
        :titleArr="['反担保', '实际担保金额（亿元）']"
        :contentArr="[showData.fdb, showData.sjdbjeyy]"
      ></cTableItem>
      <cTableItem
        :titleArr="['备注']"
        type="one"
        :contentArr="[showData.bzhu]"
      ></cTableItem>

      <div class="more" @click="getMore" v-if="!isMore">点击展开更多</div>
    </div>
    <div v-if="isMore">
      <cTableItem
        :titleArr="['融资产品全称', '融资产品简称']"
        :contentArr="[showData.rzcpqc, showData.rzcpjc]"
      ></cTableItem>
      <cTableItem
        :titleArr="['基础资产', '委托机构']"
        :contentArr="[showData.jczc, showData.wtjg]"
      ></cTableItem>

      <cTableItem
        :titleArr="['服务费', '注册机构']"
        :contentArr="[showData.fwf, showData.zcjg]"
      ></cTableItem>
      <cTableItem
        :titleArr="['委托人', '受托人']"
        :contentArr="[showData.wtr, showData.str]"
      ></cTableItem>
      <cTableItem
        :titleArr="['承租人', '出租人']"
        :contentArr="[showData.cezr, showData.czr]"
      ></cTableItem>
      <cTableItem
        :titleArr="['租赁利率', '租赁方式']"
        :contentArr="[showData.zlll, showData.zlfs]"
      ></cTableItem>

      <cTableItem
        :titleArr="['手续费（亿元）', '融资成本总额（亿元）']"
        :contentArr="[showData.sxfyy, showData.rzcbzeyy]"
      ></cTableItem>
      <cTableItem
        :titleArr="['租赁目标', '租赁设备所有权']"
        :contentArr="[showData.zlmb, showData.zlsbsyq]"
      ></cTableItem>
      <cTableItem
        :titleArr="['租金支付', '留购价款（万元）']"
        :contentArr="[showData.zjzf, showData.lgjkwy]"
      ></cTableItem>
      <cTableItem
        :titleArr="['赎回/回购条款', '出让方']"
        :contentArr="[showData.shhgtk, showData.crf]"
      ></cTableItem>

      <cTableItem
        :titleArr="['出让标的', '受让方']"
        :contentArr="[showData.crbd, showData.srf]"
      ></cTableItem>

      <cTableItem
        :titleArr="['债务人', '债权额度（亿元）']"
        :contentArr="[showData.zwr, showData.zqedyy]"
      ></cTableItem>
      <cTableItem
        :titleArr="['股权转让价（亿元）', '债权转让价款（亿元）']"
        :contentArr="[showData.gqzrjyy, showData.zqzrjkyy]"
      ></cTableItem>
      <cTableItem
        :titleArr="['其他标的转让价', '总转让价']"
        :contentArr="[showData.qtbdzrj, showData.zzrj]"
      ></cTableItem>
      <cTableItem
        :titleArr="['回购价款', '资金占用费']"
        :contentArr="[showData.hgjk, showData.zjzyf]"
      ></cTableItem>
    </div>
    <div class="title">相关公告</div>
    <div
      class="noticeBox"
      @click="open(item.url)"
      :key="index"
      v-for="(item, index) in showData.financeOtherGgs"
    >
      <div class="arrow"></div>
      <div class="sign">发行公告</div>
      <div class="content">
        <Tooltip
          :content="item.ggbt"
          max-width="1200"
          style="width: 100%"
          word-wrap
          theme="light"
          placement="top"
          >{{ item.ggbt }}</Tooltip
        >
      </div>
      <div class="time">{{ item.gmtCreate }}</div>
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";
import cTableItem from "@/components/cTableItem";
import { returnFloat } from "@/utils";

export default {
  name: "stock",
  data() {
    return {
      isMore: false,
      showData: {},
    };
  },
  computed: {
    ...mapState(["activeDetail"]),
  },
  components: {
    cTableItem,
  },
  mounted() {
    this.showData = JSON.parse(JSON.stringify(this.activeDetail));
    this.initData()
  },
  methods: {
    initData() {
      let arrTo2 = [
        "rzgmyy",
        "sxfyy",
        "rzcbzeyy",
        "lgjkwy",
        "zqedyy",
        "zqzrjkyy",
        "gqzrjyy",
        "qtbdzrj",
        "zzrj",
        "dbjeyy",
        "sjdbjeyy",
      ];
      let arrTo100 = ["rzll", "rzqgqbl", "gqbl", "zlll"];
      arrTo100.forEach((i) => {
        if (!isNaN(this.showData[i]) && this.showData[i]) {
          this.showData[i] = Number(this.showData[i] * 100).toFixed(2) + "%";
        }
      });
      arrTo2.forEach((i) => {
        if (!isNaN(this.showData[i]) && this.showData[i]) {
          this.showData[i] = returnFloat(this.showData[i], 2);
        }
      });
    },
    getMore() {
      this.isMore = true;
    },
    open(url) {
      window.open(url);
    },
    back() {
      this.$router.go(-1);
    },
  },
};
</script>
<style lang="scss" scoped>
@import "@/style/common.scss";
.stockDetail {
  width: 100%;
  height: 100%;
  box-sizing: border-box;
  background: #fff;
  padding: 20px;
  position: relative;
  .goback {
    position: absolute;
    top: 20px;
    right: 20px;
    color: #00b6ff;
    font-size: 36px;
    transform: rotateY(180deg);
    cursor: pointer;
  }
  h1 {
    font-size: 20px;
    font-weight: bold;
    color: rgba(51, 51, 51, 1);
    line-height: 26px;
    margin-bottom: 20px;
  }
  div.more {
    height: 40px;
    background: rgba(250, 250, 253, 1);
    color: #00b6ff;
    line-height: 40px;
    font-size: 14px;
    text-align: center;
    margin-top: 10px;
    cursor: pointer;
  }
  div {
    font-size: 14px;
    color: rgba(51, 51, 51, 1);
    div.table-title {
      @include flex(flex-start);
      padding-left: 20px;
      background: rgba(231, 240, 252, 1);
      font-family: MicrosoftYaHei-Bold, MicrosoftYaHei;
      font-weight: bold;
      height: 40px;
      line-height: 40px;
      border: 1px solid rgba(214, 215, 217, 1);
    }
    div {
      @include flex;
      height: 40px;
      line-height: 40px;
      .line-title:nth-of-type(1) {
        border-left: 1px solid rgba(214, 215, 217, 1);
      }
      .line-title {
        background: rgba(250, 250, 253, 1);
        width: 414px;
        border-right: 1px solid rgba(214, 215, 217, 1);
        border-bottom: 1px solid rgba(214, 215, 217, 1);
      }
      .line-content {
        background: #fff;
        width: 414px;
        border-right: 1px solid rgba(214, 215, 217, 1);
        border-bottom: 1px solid rgba(214, 215, 217, 1);
        white-space: nowrap;
        overflow: hidden;
      }
    }
  }

  .title {
    font-size: 18px;
    font-weight: bold;
    color: rgba(21, 98, 214, 1);
    margin: 20px 0;
    border-left: 4px solid #1562d6;
    height: 18px;
    line-height: 18px;
    padding-left: 10px;
  }

  .noticeBox {
    border-bottom: 1px dashed rgba(214, 215, 217, 1);
    height: 44px;
    width: 100%;
    line-height: 44px;
    @include flex(flex-start);
    font-size: 16px;
    font-family: MicrosoftYaHei;
    color: rgba(51, 51, 51, 1);

    .arrow {
      width: 0px;
      height: 0px;
      border-left: 8px solid #aaa;
      border-top: 8px solid transparent;
      border-bottom: 8px solid transparent;
      border-right: 8px solid transparent;
    }

    .sign {
      width: 60px;
      height: 24px;
      background: rgba(246, 250, 255, 1);
      border-radius: 2px;
      border: 1px solid rgba(21, 98, 214, 1);
      color: #1562d6;
      white-space: nowrap;
      line-height: 24px;
      margin-right: 10px;
    }

    .content {
      flex: 2;
      justify-content: flex-start;
      overflow: hidden;
      white-space: nowrap;
    }

    .time {
      font-size: 14px;
      color: rgba(153, 153, 153, 1);
    }
  }
}
</style>
